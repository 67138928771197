import { gql } from "@apollo/client";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "../../../atoms/components";
import {
  useAssessmentDetailedViewScreenQuery,
  useCatalogListScreenForBlockLazyQuery,
  useCatalogListScreenForBlockQuery,
  useResourceViewScreenQuery,
} from "../../../generated/graphql";
import { parseColorToStyle } from "../../../helpers/ColorHelper";
import { LoadingCentered } from "../../../molecules/components";
import {
  ScreenModal,
  TimerChancesPromptModal,
} from "../../../molecules/components/CastBlocks";
import { CatalogCard } from "../../../molecules/components/CatalogCard";
import { ChooseBatchModal } from "../ChooseBatchModal";
import { CatalogList } from "../../../pages/components";
import { Resource } from "../../../pages/components/CatalogList";
import { useState } from "react";

export interface CatalogBlockProps {
  castBlockId: string;
  screenModal?: ScreenModal;
  timerChancesPromptModal?: TimerChancesPromptModal;
}

export function CatalogBlock({
  castBlockId,
  screenModal,
  timerChancesPromptModal,
}: CatalogBlockProps) {
  const { loading, error, data } = useCatalogListScreenForBlockQuery({
    variables: {
      castBlockId,
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  const [searchParams] = useSearchParams();
  const pathArray = window.location.pathname.split("/");
  const parentScreenName = pathArray.pop();

  // TODO handle errors

  const { resources, appearance, resourceCollection, limit } =
    data?.catalogListScreen?.castBlock?.attributes?.properties?.__typename ===
      "CastBlockPropertiesCatalog"
      ? data?.catalogListScreen?.castBlock?.attributes?.properties
      : {
        appearance: null,
        limit: null,
        resourceCollection: null,
        resources: null,
      };

  const bgStyle = parseColorToStyle(appearance?.base);

  // Old card header background color to resource icon color
  const resourceIconStyle = parseColorToStyle({
    foregroundColor: appearance?.cardHeader?.backgroundColor,
  });

  let filteredResources = resources?.filter((resource) => {
    if (resource?.content?.__typename === "ResourceContentCourse") {
      let allowedActions =
        resource?.content?.courseDetails?.course?.allowedActions;
      return (
        allowedActions?.includes("take") || allowedActions?.includes("enroll")
      );
    } else {
      return true;
    }
  });

  const allowedResources = limit
    ? filteredResources?.splice(0, limit)
    : filteredResources;

  // if (limit) {
  //   console.log(allowedResources);
  //   allowedResources.splice(0, limit);
  //   console.log(allowedResources);
  // }

  if (loading) {
    return (
      <div className="relative h-60">
        <LoadingCentered></LoadingCentered>
      </div>
    );
  }

  if (!resourceCollection || allowedResources?.length === 0) {
    return <></>;
  }

  return (
    <div
      className="w-full px-10 py-6 md:px-20 animation-fade-in"
      style={bgStyle}
    >
      <div className={"flex justify-between text-2xl mb-4"}>
        <p className="overflow-hidden font-black text-gray-900 whitespace-nowrap overflow-ellipsis">
          {resourceCollection?.name}
        </p>

        {/* Link to Catalog List */}
        <Link
          to={`/catalogviewer?collection_uuid=${resourceCollection?.uuid || ""
            }&cast_block_id=${castBlockId || ""}&from_screen=${parentScreenName}`}
          className={
            (limit ? "" : "hidden") + " text-secondary-400 text-lg px-2"
          }
        >
          <span className="hidden md:inline">See more</span>
          <Icon name="chevron_right" className="text-xl" />
        </Link>
      </div>

      {/* DISPLAYED RESOURCES */}
      <ul className="flex flex-col flex-wrap items-center md:flex-row">
        {allowedResources?.map((resource, i: number) => {
          return (
            <ResourceTypeSwitch
              key={resource?.uuid}
              resource={resource || {}}
              resourceIconStyle={resourceIconStyle}
              screenModal={screenModal}
              timerChancesPromptModal={timerChancesPromptModal}
              parentScreenName={parentScreenName}
            />
          );
        })}
      </ul>
    </div>
  );
}

export const ResourceTypeSwitch = ({
  resource,
  screenModal,
  timerChancesPromptModal,
  resourceIconStyle,
  parentScreenName,
  path, // Used for DirectoryViewer
  basePath, // Used for DirectoryViewer
  fromDirectory, // Used for DirectoryViewer
}: {
  resource: Resource;
  screenModal?: ScreenModal;
  timerChancesPromptModal?: TimerChancesPromptModal;
  resourceIconStyle: any;
  parentScreenName?: string;
  path?: string // Used for DirectoryViewer
  basePath?: string // Used for DirectoryViewer
  fromDirectory?: string // Used for DirectoryViewer
}) => {
  const {
    data: resourceData,
    loading: resourceLoading,
    error: resourceError,
  } = useResourceViewScreenQuery({
    variables: {
      resourceUuid: resource.uuid as string,
    },
    skip: resource.type !== "assessment",
  });
  const examContent: any = resourceData?.resourceViewScreen?.resource?.content;
  const examUuid = examContent?.examDetails?.examUuid;
  const examTimeslotUuid = examContent?.examDetails?.examTimeslotUuid;
  const assessment = useAssessmentDetailedViewScreenQuery({
    variables: {
      examTimeslotUuid,
      resourceUuid: resource.uuid || "",
    },
    skip: resourceLoading || !examTimeslotUuid,
  });

  const timeLimit =
    assessment.data?.assessment_view_screen?.exam_timeslot?.outline?.exam
      ?.time_limit;
  const hasUserSubmissions =
    (assessment.data?.assessment_view_screen?.exam_timeslot?.user_submissions
      ?.total_count || 0) > 0;

  const [isBatchModalOpen, setIsBatchModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <li className="animation-fade-in">
      {/*OLD :  If Course dont go directly to view */}
      {/*NEW :  IF ENROLLED GO DIRECTLY */}
      {/*NEW NEW :  If enrolled, select batch */}
      {resource.content?.__typename === "ResourceContentCourse" &&
        !resource.content.courseDetails?.course?.isEnrolled ? (
        <button
          onClick={() => {
            screenModal?.objectFunction.setCourseCardModal(true);
            screenModal?.objectFunction.setCourseResource({
              description: resource?.description as string,
              image: resource?.coverUri as string,
              name: resource?.name as string,
              uuid: resource?.uuid as string,
            });
          }}
        >
          <CatalogCard
            name={resource.name}
            coverUri={resource.coverUri}
            type={resource.type}
            resourceIconStyle={resourceIconStyle}
          />
        </button>
      ) : resource.content?.__typename === "ResourceContentCourse" &&
        resource.content.courseDetails?.course?.isEnrolled ? (
        <>
          <button
            onClick={() => {
              let activeEnrollmentSchedules: number = 1;

              if (resource.content?.__typename === "ResourceContentCourse")
                activeEnrollmentSchedules =
                  resource.content.courseDetails?.course?.enrolledSchedules?.filter(
                    (enrolledSchedule) => enrolledSchedule?.status === "ONGOING"
                  ).length || 1;
              if (activeEnrollmentSchedules > 1) setIsBatchModalOpen(true);
              else {
                navigate(
                  `/view?course_schedule_uuid=${resource.content?.__typename === "ResourceContentCourse" &&
                    resource.content.courseDetails?.course?.enrolledSchedules
                    ? resource.content.courseDetails?.course?.enrolledSchedules.find(
                      (enrollmentSchedule) => enrollmentSchedule?.status === "ONGOING"
                    )?.uuid
                    : ""
                  }&resource_uuid=${resource?.uuid || ""}&from_screen=${parentScreenName}${path ? `&path=${path}&base_path=${basePath}&from_directory=${fromDirectory}` : ""
                  }`
                );
              }
            }}
          >
            <CatalogCard
              name={resource.name}
              coverUri={resource.coverUri}
              type={resource.type}
              resourceIconStyle={resourceIconStyle}
            />
          </button>
          <ChooseBatchModal
            resource={resource}
            courseUuid={resource.content.courseDetails?.course.uuid || ""}
            isModalOpen={isBatchModalOpen}
            setIsModalOpen={setIsBatchModalOpen}
          />
        </>
      ) : resource.type === "assessment" && assessment.loading ? (
        <div className="relative z-0 flex flex-col items-center w-48 h-48 p-4 mb-2 bg-gray-100 rounded-2xl md:mr-2">
          <LoadingCentered></LoadingCentered>
        </div>
      ) : (resource.type === "assessment" && hasUserSubmissions) ||
        timeLimit ? (
        <button
          onClick={() => {
            timerChancesPromptModal?.objectFunction.setTimePromptModal(true);
            timerChancesPromptModal?.objectFunction.setResourceUuid(
              resource?.uuid || ""
            );
          }}
        >
          <CatalogCard
            name={resource.name}
            coverUri={resource.coverUri}
            type={resource.type}
            resourceIconStyle={resourceIconStyle}
          />
        </button>
      ) : (
        <Link
          to={`/view?resource_uuid=${resource.uuid}&from_screen=${parentScreenName}${path ? `&path=${path}&base_path=${basePath}&from_directory=${fromDirectory}` : ""
            }`}
        >
          <CatalogCard
            name={resource.name}
            coverUri={resource.coverUri}
            type={resource.type}
            resourceIconStyle={resourceIconStyle}
          />
        </Link>
      )}
    </li>
  );
};
