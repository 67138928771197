import { gql } from "@apollo/client";
import {
  FormEvent,
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
} from "react";
import {
  Button,
  Icon,
  InputRadio,
  InputTextArea,
  Label,
  Loading,
  Select,
} from "../../../atoms/components";
import {
  AccountAndUserDetailsSidePanel,
  InputWithLabel,
  Toast,
} from "../../../molecules/components";
import {
  AccountAndUserDetailsScreenFormTab as AccountAndUserDetailsScreenFormTabOriginal,
  useAccountAndUserDetailsQuery,
  useUpdateAccountMutation,
  useUpdateProfileMutation,
  useGetFormItemsLazyQuery,
  useFormSubmitMutation,
  AccountAndUserDetailsScreenFormTabSubmit,
  AccountAndUserDetailsScreenFormTabSubmitFormFields,
  AccountAndUserDetailsScreenCustomField as AccountAndUserDetailsScreenCustomFieldOriginal,
  useGetFormSubmissionSummariesLazyQuery,
} from "../../../generated/graphql";
import { useErrorHandler } from "react-error-boundary";
import { LoadingCentered } from "../../../molecules/components/LoadingCentered";
import { NavBarLayout } from "../../../templates/components/NavBarLayout";
import { isGuestContext } from "../../../context/components/IsGuest";
import { CamelCasedType } from "../../../helpers/components";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import "./AccountAndUserDetails.css";
import { set } from "lodash";

// Flags for Deped

const isDepedProd = false;

export type AccountAndUserDetailsScreenFormTab =
  CamelCasedType<AccountAndUserDetailsScreenFormTabOriginal>;

export type AccountAndUserDetailsScreenCustomField =
  CamelCasedType<AccountAndUserDetailsScreenCustomFieldOriginal>;

export interface AccountAndUserDetailsProps {
  profileImage?: string;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
}

export function ChangeEmailForm({ email }: { email?: string }) {
  const [inEditMode, setInEditMode] = useState(false);

  const [isToastPresent, setIsToastPresent] = useState(false);

  const [newEmail, setNewEmail] = useState<string>("");
  const [retypeEmail, setRetypeEmail] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const [updateAccountMutation, { data, loading, error }] =
    useUpdateAccountMutation({
      variables: {
        details: {
          email: newEmail,
          current_password: currentPassword,
        },
      },
      onError: (error) => {
        console.log("Error", error);
      },
    });

  const changeEmail = async (event: FormEvent) => {
    event.preventDefault();
    setInEditMode(false);
    setIsToastPresent(true);
    await updateAccountMutation();
  };

  const onCancel = () => {
    setInEditMode(false);
    setNewEmail("");
    setRetypeEmail("");
    setCurrentPassword("");
    setIsToastPresent(false);
  };

  if (loading) {
    return (
      <div className="relative h-40">
        <LoadingCentered />
      </div>
    );
  }

  if (!inEditMode) {
    return (
      <div>
        {!loading && isToastPresent && (
          <Toast
            title={!error ? "Email Sent!" : "Invalid Password!"}
            message={
              !error
                ? "An email was sent to you. Please follow instructions stated on the email to continue."
                : "Password is incorrect"
            }
            toastStatus={!error ? "success" : "error"}
            onClose={() => setIsToastPresent(false)}
          ></Toast>
        )}

        <InputWithLabel
          id="email"
          _label="Email"
          type="email"
          aria-label="Email"
          value={email}
          disabled
        />
        <button
          type="button"
          aria-label="Click to change email"
          onClick={() => setInEditMode(true)}
          className="my-2 uppercase cursor-pointer text-primary-500 hover:text-primary-600"
        >
          Change email
        </button>
      </div>
    );
  } else {
    return (
      <form onSubmit={changeEmail}>
        <InputWithLabel
          id="new-mail"
          _label="New Email"
          type="email"
          required={true}
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />

        <InputWithLabel
          id="retype-email"
          _label="Retype New Email"
          type="email"
          required={true}
          value={retypeEmail}
          onChange={(e) => setRetypeEmail(e.target.value)}
        />

        <InputWithLabel
          id="current-password"
          _label="Current Password"
          type="password"
          aria-label="Enter current password"
          required={true}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />

        <div className="flex justify-between">
          <Button
            className="my-3"
            type="submit"
            role="submit"
            disabled={!newEmail || newEmail !== retypeEmail}
          >
            Save Changes
          </Button>

          <Button
            className="my-3"
            type="button"
            onClick={onCancel}
            color="error"
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export function ChangePasswordForm() {
  const [inEditMode, setInEditMode] = useState(false);
  const [isToastPresent, setIsToastPresent] = useState(false);

  const [newPassword, setNewPassword] = useState<string>("");
  const [retypePassword, setRetypePassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<{
    errorTitle: string;
    errorDescription: string;
  }>({
    errorTitle: "",
    errorDescription: "",
  });

  const [changePasswordMutation, { data, loading, error }] =
    useUpdateAccountMutation({
      variables: {
        details: {
          password: newPassword,
          password_confirmation: retypePassword,
          current_password: currentPassword,
        },
      },
      onError: (error) => {
        const wrongPassword = (
          error.graphQLErrors[0].extensions.response as { body: any }
        )?.body.error_details.current_password;
        if (wrongPassword) {
          setErrorMessage({
            errorTitle: "Invalid Password!",
            errorDescription: "Current Password is incorrect",
          });
        } else {
          setErrorMessage({
            errorTitle: "Invalid Password!",
            errorDescription:
              "Password must have a minimum of 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character. Only the following special characters are allowed: @$!%*#?&",
          });
        }
      },
    });

  const changePassword = async (event: FormEvent) => {
    event.preventDefault();
    setInEditMode(false);
    setIsToastPresent(true);

    await changePasswordMutation();
  };

  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (regex.test(password)) {
      setPasswordIsInvalid(false);
    } else {
      setPasswordIsInvalid(true);
    }
  };

  const toggleToEditMode = () => {
    setInEditMode(true);

    if (!error) {
      setNewPassword("");
      setRetypePassword("");
      setCurrentPassword("");
    }
  };

  const onCancel = () => {
    setInEditMode(false);
    setNewPassword("");
    setRetypePassword("");
    setCurrentPassword("");
    setIsToastPresent(false);
  };

  if (loading) {
    return (
      <div className="relative h-40">
        <LoadingCentered />
      </div>
    );
  }

  if (!inEditMode) {
    return (
      <div>
        {!loading && isToastPresent && (
          <Toast
            title={!error ? "Password Changed!" : errorMessage.errorTitle}
            message={
              !error
                ? "Password has been changed successfully."
                : errorMessage.errorDescription
            }
            toastStatus={!error ? "success" : "error"}
            onClose={() => setIsToastPresent(false)}
          ></Toast>
        )}

        <InputWithLabel
          id="password"
          _label="Password"
          type="password"
          aria-label="Password"
          aria-required="true"
          value={"            "}
          disabled={true}
        />
        <button
          type="button"
          aria-label="Click to change password"
          onClick={toggleToEditMode}
          className="my-2 uppercase cursor-pointer text-primary-500 hover:text-primary-600"
        >
          Change password
        </button>
      </div>
    );
  } else {
    return (
      <form onSubmit={changePassword}>
        <InputWithLabel
          id="new-password"
          _label="New Password"
          type="password"
          value={newPassword}
          required={true}
          onChange={(e) => {
            setNewPassword(e.target.value);
            onPasswordChange(e);
          }}
        />
        <div className="min-h-[12px] relative">
          {/* Reserve space */}
          {passwordIsInvalid && newPassword && (
            <p className="text-xs bottom-4 text-error-400">
              Password must have a minimum of 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character. Only the following special characters are allowed: @$!%*#?&
            </p>
          )}
        </div>

        <InputWithLabel
          id="retype-password"
          _label="Retype new password"
          type="password"
          value={retypePassword}
          required={true}
          onChange={(e) => setRetypePassword(e.target.value)}
        />
        <div className="min-h-[12px] relative">
          {retypePassword !== newPassword && retypePassword.length !== 0 && (
            <p className="text-xs bottom-4 text-error-400">
              Passwords do not match. Please retype password
            </p>
          )}
        </div>

        <InputWithLabel
          id="current-password"
          _label="Type current password"
          type="password"
          value={currentPassword}
          required={true}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />

        <div className="flex justify-between">
          <Button
            className="my-3"
            type="submit"
            role="submit"
            disabled={
              !newPassword ||
              newPassword !== retypePassword ||
              !currentPassword ||
              passwordIsInvalid
            }
          >
            Save Changes
          </Button>

          <Button
            className="my-3"
            type="button"
            onClick={onCancel}
            color="error"
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export function AccountAndUserDetailsProfile(props: {
  firstName: string;
  lastName: string;
  customFields: any;
}) {
  const [firstName, setFirstName] = useState<string>(props.firstName);
  const [lastName, setLastName] = useState<string>(props.lastName);
  const [customFieldStates, setCustomFieldStates] = useState({});

  const handleInputChange = (name: string, value: any) => {
    setCustomFieldStates((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [isToastPresent, setIsToastPresent] = useState(false);

  const [updateProfileMutation, { data, loading, error }] =
    useUpdateProfileMutation({
      variables: {
        details: {
          given_name: firstName,
          family_name: lastName,
          custom_fields: customFieldStates,
        },
      },
    });

  useErrorHandler(error);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await updateProfileMutation();

    setIsToastPresent(true);
  };

  if (loading) {
    return (
      <div className="relative h-40">
        <LoadingCentered />
      </div>
    );
  }

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
      {!loading && isToastPresent && (
        <Toast
          title="Changes Saved!"
          message="changes saved successfully."
          onClose={() => setIsToastPresent(false)}
        ></Toast>
      )}

      <div className="flex flex-col w-full md:flex-row ">
        <InputWithLabel
          id="firstname"
          _label="First Name"
          aria-label="First Name"
          aria-required="true"
          type="text"
          value={firstName}
          className="w-full md:pr-2"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <InputWithLabel
          id="lastname"
          _label="Last Name"
          aria-label="Last Name"
          aria-required="true"
          type="text"
          value={lastName}
          className="w-full"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>

      {props.customFields !== null
        ? props.customFields.map((customField: any) => {
            // Hindi daw kasi input talaga si select. Nested HTML element daw siya so... magugulo po yung props ng InputWithLabel kasi pang input lang, specially sa extends part
            if (!customField.options) {
              return (
                <InputWithLabel
                  id={customField.inputName}
                  key={"customFieldWithoutOptions:" + customField.label}
                  _label={customField.label}
                  aria-label={customField.label}
                  required={customField.required}
                  type={customField.inputType}
                  name={customField.inputName}
                  disabled={customField.disabled}
                  value={
                    customFieldStates[
                      customField.inputName as keyof typeof customFieldStates
                    ] === ""
                      ? ""
                      : customFieldStates[
                          customField.inputName as keyof typeof customFieldStates
                        ] ||
                        customField.value ||
                        ""
                  }
                  onChange={(e) => {
                    handleInputChange(customField.inputName, e.target.value);
                  }}
                  className="w-full md:w-1/2 md:md:pr-2"
                ></InputWithLabel>
              );
            } else if (customField.options) {
              return (
                <div
                  key={"customField with options:" + customField.label}
                  className="w-full md:w-1/2 md:pr-2"
                >
                  <h2 className="block mt-3 mb-2 text-gray-400">
                    {customField.required ? (
                      <span className="mr-1 text-error-400">*</span>
                    ) : (
                      ""
                    )}
                    {customField.label}
                  </h2>
                  <Select
                    id={customField.inputName}
                    className="w-full"
                    disabled={customField.disabled}
                    onChange={(e) => {
                      if (e.target.value !== "Choose option")
                        handleInputChange(
                          customField.inputName,
                          e.target.value
                        );
                    }}
                    value={
                      customFieldStates[
                        customField.inputName as keyof typeof customFieldStates
                      ] ||
                      customField.value ||
                      "Choose option"
                    }
                  >
                    <option value="Choose option" disabled>
                      Choose option
                    </option>
                    {customField.options.map((optionValue: string) => {
                      return (
                        <option
                          key={optionValue}
                          value={optionValue}
                          id={optionValue}
                        >
                          {optionValue}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              );
            }
          })
        : ""}

      <Button
        type="submit"
        role="submit"
        tabIndex={0}
        aria-label="Save"
        className="ml-auto w-fit"
      >
        Save
      </Button>
    </form>
  );
}

interface ClamimAccountFormProps {
  first: string;
  last: string;
}

export function ClaimAccountForm({ first, last }: ClamimAccountFormProps) {
  const [newEmail, setNewEmail] = useState<string>("");
  // const [retypeEmail, setRetypeEmail] = useState<string>("");
  // const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>(first);
  const [lastName, setLastName] = useState<string>(last);

  const [error, setError] = useState(false);
  const [isPasswordFocus, setIsPasswordFocus] = useState(false);

  const [isToastPresent, setIsToastPresent] = useState(false);

  const [
    claimAccountMutation,
    {
      data: emailMutationData,
      loading: emailMutationLoading,
      error: emailMutationError,
    },
  ] = useUpdateAccountMutation({
    variables: {
      details: {
        email: newEmail,
        password: newPassword,
      },
    },
    onError: () => {
      setError(true);
    },
  });

  const [
    mutateProfileFunction,
    {
      data: profileMutationData,
      loading: profileMutationLoading,
      error: profileMutationError,
    },
  ] = useUpdateProfileMutation({
    variables: {
      details: {
        given_name: firstName,
        family_name: lastName,
      },
    },
    onError: () => {
      setError(true);
    },
  });

  // useErrorHandler(emailMutationError);
  // useErrorHandler(passwordMutationError);
  // useErrorHandler(profileMutationError);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await claimAccountMutation();
    // await mutatePasswordFunction();
    if (!emailMutationError) await mutateProfileFunction();

    setIsToastPresent(true);
  };

  if (
    emailMutationLoading &&
    // passwordMutationLoading &&
    profileMutationLoading
  ) {
    return (
      <div className="relative h-40">
        <LoadingCentered />
      </div>
    );
  }

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
      {!emailMutationLoading && !profileMutationLoading && isToastPresent ? (
        !error ? (
          <Toast
            title="Account Claimed!"
            message="Please check your email to finalize changes!"
            onClose={() => setIsToastPresent(false)}
          ></Toast>
        ) : (
          <Toast
            title="Account Claim Unsuccessful!"
            message="Please check your claim details"
            toastStatus="error"
            onClose={() => setIsToastPresent(false)}
          ></Toast>
        )
      ) : (
        ""
      )}

      {/* New Email */}
      <InputWithLabel
        id="new-mail"
        _label="New Email"
        type="email"
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        required
      />

      {/* <InputWithLabel
        id="retype-email"
        _label="Retype New Email"
        type="email"
        value={retypeEmail}
        onChange={(e) => setRetypeEmail(e.target.value)}
      /> */}

      {/* New Password */}

      <InputWithLabel
        id="new-password"
        _label="New Password"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        onFocus={() => {
          setIsPasswordFocus(true);
        }}
        onBlur={() => {
          setIsPasswordFocus(false);
        }}
        required
      />

      <p className="text-xs">
        {isPasswordFocus
          ? "Password must have a minimum of 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character. Only the following special characters are allowed: @$!%*#?&"
          : ""}
      </p>
      {/* First Name of the User */}
      <InputWithLabel
        id="firstname"
        _label="First Name"
        aria-label="First Name"
        aria-required="true"
        type="text"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        // placeholder="Guest First Name"
        required
      />
      <InputWithLabel
        id="lastname"
        _label="Last Name"
        aria-label="Last Name"
        aria-required="true"
        type="text"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        // placeholder="Guest Last Name"
        required
      />
      <span>
        <Button type="submit" role="submit" tabIndex={0} aria-label="Save">
          Save
        </Button>
      </span>
    </form>
  );
}

function FormItemTypeSwitch({
  formItem,
  handleFormChanges,
}: {
  formItem?: AccountAndUserDetailsScreenCustomField;
  handleFormChanges({
    name,
    value,
    file,
  }: {
    name: string;
    value?: string | number | boolean | Date | undefined;
    file?: File;
  }): void;
}) {
  const nonInputElementArray = ["select", "textarea", "radio"];

  if (!formItem?.inputType) {
    return <></>;
  }

  switch (formItem?.inputType) {
    case "textarea": {
      return (
        <div>
          <Label className="block mt-3 mb-2 text-gray-400">
            {formItem.required ? (
              <span className="mr-1 text-error-400">*</span>
            ) : (
              ""
            )}
            {formItem.label}
          </Label>
          <InputTextArea
            key={formItem?.id}
            required={formItem.required || false}
            onChange={(e) =>
              handleFormChanges({
                name: formItem?.inputName || "",
                value: e.target.value,
              })
            }
          />
        </div>
      );
    }
    case "select": {
      return (
        <div>
          <Label className="block mt-3 mb-2 text-gray-400">
            {formItem.required ? (
              <span className="mr-1 text-error-400">*</span>
            ) : (
              ""
            )}
            {formItem.label}
          </Label>
          <Select
            key={formItem?.id}
            className="w-full"
            required={formItem.required || false}
            onChange={(e) =>
              handleFormChanges({
                name: formItem?.inputName || "",
                value: e.target.value,
              })
            }
          >
            <option value="Choose option">Choose option</option>
            {formItem.options?.map((option) => {
              return <option key={option}>{option}</option>;
            })}
          </Select>
        </div>
      );
    }
    case "radio": {
      return (
        <div>
          <Label className="block mt-3 mb-2 text-gray-400">
            {formItem.required ? (
              <span className="mr-1 text-error-400">*</span>
            ) : (
              ""
            )}
            {formItem.label}
          </Label>

          {formItem.options?.map((option) => {
            return (
              <div key={option} className="flex items-center ">
                <InputRadio
                  required={formItem.required || false}
                  key={option}
                  name={formItem.inputName || ""}
                  value={option || ""}
                  onChange={(e) =>
                    handleFormChanges({
                      name: formItem?.inputName || "",
                      value: e.target.value,
                    })
                  }
                />
                <p className="ml-2">{option}</p>
              </div>
            );
          })}
        </div>
      );
    }
    case "file": {
      return (
        <div key={formItem?.id}>
          <InputWithLabel
            _label={formItem?.label || ""}
            type={formItem?.inputType || ""}
            required={formItem.required || false}
            onChange={(e) => {
              const file = e.target.files?.[0];

              if (file)
                handleFormChanges({
                  name: formItem?.inputName || "",
                  value: file.name,
                  file: file,
                });
            }}
          />
        </div>
      );
    }
    default:
      return (
        <div>
          <InputWithLabel
            _label={formItem?.label || ""}
            type={formItem?.inputType || ""}
            required={formItem.required || false}
            onChange={(e) =>
              handleFormChanges({
                name: formItem?.inputName || "",
                value: e.target.value,
              })
            }
          />
        </div>
      );
  }
}

export function ApprovalStatusIcon({
  approvalStatus,
}: {
  approvalStatus: string;
}) {
  switch (approvalStatus) {
    case "rejected": {
      return <Icon name="error" className="text-xl text-error-700" />;
    }
    case "pending": {
      return <Icon name="error" className="text-xl text-info-700" />;
    }
    default: {
      return <>{approvalStatus}</>;
    }
  }
}

export function ProfileSectionSwitch({
  currentProfileSection,
  email,
  firstName,
  lastName,
  customFields,
  formTabs,
  refetch,
}: {
  currentProfileSection: string;
  email: string;
  firstName: string;
  lastName: string;
  customFields: any;
  formTabs: AccountAndUserDetailsScreenFormTab[];
  refetch: () => void;
}) {
  // Get the specific tab being viewed
  const currentFormTab = formTabs.find(
    (formTab) => formTab.name === currentProfileSection
  );

  const [addView, setAddView] = useState<boolean>(false);
  const [getFormItems, formItems] = useGetFormItemsLazyQuery({
    fetchPolicy: "network-only",
  });

  const [getFormSummaries, formSummaries] =
    useGetFormSubmissionSummariesLazyQuery({
      fetchPolicy: "network-only",
    });

  const [formFields, setFormFields] = useState<
    AccountAndUserDetailsScreenFormTabSubmitFormFields[]
  >([]);
  const [formResponseDetails, setFormResponseDetails] =
    useState<AccountAndUserDetailsScreenFormTabSubmit>({
      form_tab_id: currentFormTab?.id || "",
      form_fields: formFields,
    });

  const [toastVisible, setToastVisible] = useState<boolean>(false);

  function handleFormChanges({
    name,
    value,
    file,
  }: {
    name: string;
    value?: string | number | Date | boolean;
    file?: File;
  }) {
    if (!formFields.some((field) => field.name === name)) {
      setFormFields((prev) => [
        ...prev,
        {
          name: name,
          file: file as unknown as string,
          value: value,
        },
      ]);
    } else {
      const updatedFormFields = formFields.map((field) => {
        if (field.name === name)
          return {
            name: name,
            file: file,
            value: value,
          };

        return field;
      });

      setFormFields(updatedFormFields);
    }
  }

  useEffect(() => {
    setAddView(false);

    // Get Form Summaries for the current tab of meron lang tab id
    if (currentFormTab?.id)
      getFormSummaries({
        variables: {
          formTabId: currentFormTab.id,
        },
      });

    // Cleanup responses
    setFormResponseDetails({
      form_tab_id: currentFormTab?.id || "",
      form_fields: [],
    });

    // Cleanup form fields state
    setFormFields([]);
  }, [currentProfileSection]);

  useEffect(() => {
    setFormResponseDetails((prev) => {
      return {
        form_tab_id: prev.form_tab_id || currentFormTab?.id || "",
        form_fields: [...formFields],
      };
    });
  }, [formFields]);

  const [
    submitForm,
    {
      data: submissionData,
      loading: submissionLoading,
      error: submissionError,
    },
  ] = useFormSubmitMutation({
    onCompleted: () => {
      setAddView(false);
      setFormFields([]);
      setToastVisible(true);
      refetch();
    },
  });

  if (submissionLoading) {
    return <LoadingCentered />;
  }

  switch (currentProfileSection) {
    case "User Details":
      return (
        <div
          className={`md:flex ${
            isDepedProd ? "" : "flex-col"
          } justify-items-stretch gap-4 w-full`}
        >
          {!isDepedProd && (
            <div className="flex gap-4 pb-4 border-b border-gray-300">
              <div className="flex-1">
                <ChangeEmailForm email={email as string}></ChangeEmailForm>
              </div>

              <div className="flex-1 w-full">
                <ChangePasswordForm></ChangePasswordForm>
              </div>
            </div>
          )}

          {!isDepedProd && (
            <AccountAndUserDetailsProfile
              firstName={firstName}
              lastName={lastName}
              customFields={customFields}
            ></AccountAndUserDetailsProfile>
          )}

          {isDepedProd && (
            <>
              <div className="flex-1">
                <ChangeEmailForm email={email as string}></ChangeEmailForm>
              </div>

              <div className="flex-1 w-full">
                <ChangePasswordForm></ChangePasswordForm>
              </div>
            </>
          )}
        </div>
      );
    case "Additional User Details":
      return (
        <AccountAndUserDetailsProfile
          firstName={firstName}
          lastName={lastName}
          customFields={customFields}
        ></AccountAndUserDetailsProfile>
      );
    default:
      return (
        <div>
          <div className={`${toastVisible ? "block" : "hidden"}`}>
            <Toast
              message="Submission Successful"
              title="Great!"
              toastStatus="success"
              onClose={() => setToastVisible(false)}
              closeDelay={2000}
            />
          </div>

          {!addView ? (
            <Button
              className="my-2"
              onClick={(e) => {
                e.stopPropagation();
                setAddView(true);
                getFormItems({
                  variables: {
                    formTabId: currentFormTab?.id || "",
                  },
                  fetchPolicy: "cache-first",
                });
              }}
            >
              + Add
            </Button>
          ) : (
            <button className="text-lg font-bold">
              <Icon
                name="chevron_left"
                className="w-8 h-8 text-2xl text-center text-white bg-gray-700 rounded-lg"
                onClick={() => {
                  setAddView(false);
                  setFormFields([]);
                }}
                role="button"
              />
            </button>
          )}

          {!addView && formSummaries.loading && (
            <div className="w-40 h-40 mx-auto my-auto mt-20">
              <Loading />
            </div>
          )}

          {!addView &&
            !formSummaries.loading &&
            formSummaries.data?.accountAndUserDetails?.formTab?.formSubmissionSummaries?.map(
              (formSubmissionSummary) => {
                return (
                  <div
                    key={formSubmissionSummary?.id}
                    className="p-2 mb-2 bg-white rounded shadow-md "
                  >
                    <div className="flex items-center justify-between w-full">
                      <h2 className="font-bold text-gray-700">
                        {formSubmissionSummary?.title}
                      </h2>
                      <ApprovalStatusIcon
                        approvalStatus={
                          formSubmissionSummary?.approvalStatus || ""
                        }
                      />
                    </div>

                    <h3 className="text-sm text-gray-500">
                      {formSubmissionSummary?.subtitle}
                    </h3>
                    <h3 className="font-bold text-gray-500">
                      {dateFormattingSchedules(
                        formSubmissionSummary?.dateCreated ||
                          "2023-10-02T07:29:46.449Z"
                      )}
                    </h3>
                    <p className="text-xs text-primary-600">
                      {formSubmissionSummary?.fileDescription}
                    </p>
                  </div>
                );
              }
            )}

          {addView && formItems.loading && (
            <div className="w-40 h-40 mx-auto my-auto mt-20">
              <Loading />
            </div>
          )}

          {addView && !formItems.loading && formItems.data && (
            <form
              onSubmit={(e) => {
                e.preventDefault();

                const formData = new FormData();

                formData.append("details", "{}");

                // formData.append("form_tab_id", formResponseDetails.form_tab_id || "")
                // formResponseDetails.form_fields?.forEach((field, index) => {
                //   formData.append(
                //     `form_fields[${index}].name`,
                //     field?.name || ""
                //   );
                //   formData.append(
                //     `form_fields[${index}].value`,
                //     field?.value || ""
                //   );
                //   if (field?.file)
                //     formData.append(`form_fields[${index}].name`, field?.file);
                // });

                submitForm({
                  variables: {
                    details: formResponseDetails,
                  },
                });
              }}
            >
              {formItems.data.accountAndUserDetailsScreen?.formTab?.formItems?.map(
                (formItem) => {
                  return (
                    <FormItemTypeSwitch
                      key={formItem?.id}
                      formItem={formItem || {}}
                      handleFormChanges={handleFormChanges}
                    />
                  );
                }
              )}
              <Button className="mt-2 ml-auto" type="submit">
                Submit
              </Button>
            </form>
          )}
        </div>
      );
  }
}

export function AccountAndUserDetails({
  ...props
}: AccountAndUserDetailsProps) {
  const { loading, data, error, refetch } = useAccountAndUserDetailsQuery();

  const refetchData = () => {
    refetch();
  };

  useErrorHandler(error);

  const userInfo = data?.accountAndUserDetailsScreen?.userInfo;
  const userProfile = data?.accountAndUserDetailsScreen?.userProfile;
  const customFields = data?.accountAndUserDetailsScreen?.customFields;
  const formTabs = data?.accountAndUserDetailsScreen?.formTabs;

  const firstName = (userProfile?.givenName || userInfo?.firstName) as string;

  const lastName = (userProfile?.familyName || userInfo?.lastName) as string;

  const { isGuest } = useContext(isGuestContext);

  const defaultProfileSections = ["User Details", "Additional User Details"];
  const dynamicProfileSections =
    formTabs?.map((formTab, index: number) => {
      if (formTab?.name) return formTab?.name as string;

      return "Unknown Tab" + index;
    }) || [];

  const profileSectionArray = [
    ...defaultProfileSections,
    ...dynamicProfileSections.filter(
      (section) => !defaultProfileSections.includes(section)
    ),
  ];

  const [currentProfileSection, setCurrentProfileSection] =
    useState<string>("User Details");

  return (
    <NavBarLayout className="flex flex-col w-full h-full min-h-screen bg-gray-200 overflow-clip">
      <div className="flex flex-1 max-w-full">
        {isDepedProd && (
          <AccountAndUserDetailsSidePanel
            className="hidden w-1/4 px-4 py-6 lg:block"
            currentPage="accountAndUserDetails"
          />
        )}

        <main
          className={`py-6 px-4 h-full flex-1 max-w-full lg:max-w-[75%] ${
            isDepedProd ? "" : "mx-auto"
          }`}
        >
          <h1 className="mb-4 text-2xl font-black text-gray-700 ">
            Account and User Details
          </h1>

          {/* Profile Page Navigation */}

          {isDepedProd && (
            <nav
              className={`inline-flex overflow-x-auto space-x-2 max-w-full mb-4 custom-scrollbar scrollbar-color-primary ${
                currentProfileSection === "Add Training" ? "hidden" : ""
              } `}
            >
              {profileSectionArray.map((profileSectionName, index) => {
                return (
                  <button
                    key={index}
                    onClick={(e) =>
                      setCurrentProfileSection(profileSectionName)
                    }
                    className={`bg-primary-200 hover:bg-primary-300 whitespace-nowrap ${
                      currentProfileSection === profileSectionName
                        ? "bg-primary-300"
                        : ""
                    } text-primary-700 py-1 px-2 rounded-md shadow-md transition-colors duration-300`}
                  >
                    {profileSectionName}
                  </button>
                );
              })}
            </nav>
          )}

          {loading ? (
            <LoadingCentered></LoadingCentered>
          ) : isGuest ? (
            <div>
              <div className="p-12 my-12 bg-white border rounded-lg ">
                <h2 className="text-lg font-bold text-gray-700">
                  Claim account
                </h2>

                <h3 className="text-sm text-gray-599">
                  To save your progress we highly advice to claim your accout by
                  providing the required details below
                </h3>

                <ClaimAccountForm
                  first="Guest FirstName"
                  last="Guest LastName"
                />
              </div>
            </div>
          ) : (
            <div>
              {isDepedProd && (
                <h2 className="text-lg font-bold">{currentProfileSection}</h2>
              )}

              {/* Add Button, not visible for non form tabs, hidden when already inside the form */}

              <div
                className={`p-4 rounded-lg border shadow-md bg-white ${
                  !defaultProfileSections.includes(currentProfileSection)
                    ? "p-0 bg-gray-200 shadow-none"
                    : ""
                }`}
              >
                <ProfileSectionSwitch
                  currentProfileSection={currentProfileSection}
                  email={userInfo?.email || ""}
                  firstName={firstName}
                  lastName={lastName}
                  customFields={customFields}
                  formTabs={formTabs as AccountAndUserDetailsScreenFormTab[]}
                  refetch={refetchData}
                />
              </div>
            </div>
          )}
        </main>
      </div>
    </NavBarLayout>
  );
}
