import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Image, InputTextArea } from "../../../atoms/components";
import { Button } from "../../../atoms/components";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LoadingCentered, Toast } from "../../../molecules/components";
import { ResourceContentAssessmentV2 } from "../../../pages/components/AssessmentViewerV2";
import { gql } from "@apollo/client";
import {
  useFreedomWallSubmitMutation,
  FreedomWallResult as FreedomWallResultOriginal,
} from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";
import { ExamResponseV2 } from "../ViewResourceBuriBooks";
import {
  InsideCourseResponses,
  OutsideCourseResponses,
} from "../../../pages/components/AssessmentViewerV2";

export type FreedomWallResult = CamelCasedType<FreedomWallResultOriginal>;
type FreedomWallResponse =
  | InsideCourseResponses
  | OutsideCourseResponses
  | ExamResponseV2;

const FreedomWallSubmit = gql`
  mutation FreedomWallSubmit(
    $updatedSubmission: ExamSubmissionInputV2!
    $submissionUuid: String!
    $blockUuid: String
    $courseScheduleUuid: String
  ) {
    assessmentSubmitUpdateV2: assessment_submit_update_v2(
      updated_submission: $updatedSubmission
      submission_uuid: $submissionUuid
      block_uuid: $blockUuid
      course_schedule_uuid: $courseScheduleUuid
    ) {
      __typename
      submissionResult: submission_result {
        responses {
          freedomWallResponses: freedom_wall_responses {
            userDetails: user_details {
              firstName: first_name
              lastName: last_name
              profileIconUrl: profile_icon_url
            }
          }
          feedbackMessage: feedback_message
        }
      }
      courseBlockSubmissionResult: course_block_submission_result {
        responses {
          freedomWallResponses: freedom_wall_responses {
            uuid
            shortInput: short_input

            longInput: long_input
            userDetails: user_details {
              firstName: first_name
              lastName: last_name
              profileIconUrl: profile_icon_url
            }
          }
          feedbackMessage: feedback_message
        }
      }
    }
  }
`;

export interface ViewResourceFreedomWallProps {
  content: ResourceContentAssessmentV2;
  cookRecipes?: {
    cookAsCompletedInsideCourse: () => void;
    cookAttemptInsideCourse: () => void;
    cookAsCompletedOutsideCourse: () => void;
    cookAttemptOutsideCourse: () => void;
  };
}

export function ViewResourceFreedomWall({
  content,
  cookRecipes,
}: ViewResourceFreedomWallProps) {
  const sectionWithItemFW =
    content?.examDetails?.examItemSet?.outline?.exam?.sections?.map(
      (section) => {
        return section?.items?.find((item) => item?.type === "FW");
      }
    );

  const freedomWallItem = sectionWithItemFW?.find(
    (item) => item?.type === "FW"
  );

  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");
  const searchValue = searchParams.get("search_value");
  const blockUuid = searchParams.get("course_block_uuid");
  const courseUuid = searchParams.get("course_uuid");
  const scheduleUuid = searchParams.get("course_schedule_uuid");

  const [freedomWallText, setFreedomWallText] = useState(
    freedomWallItem?.draftValue?.longInput || ""
  );
  const submissionUuid =
    content.examDetails?.examItemSet?.submission?.uuid || "";
  const [submissionStatus, setSubmissionStatus] = useState<string>("");

  const [finishedSubmission, setFinishedSubmission] = useState<boolean>(false);
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [isAnonymous, setIsAnonymous] = useState(false);

  const [submitFreedomWallResponse, { data, loading, error }] =
    useFreedomWallSubmitMutation({
      variables: {
        submissionUuid: submissionUuid,
        blockUuid: blockUuid,
        courseScheduleUuid: scheduleUuid,
        updatedSubmission: {
          status: submissionStatus,
          responses: [
            {
              item: freedomWallItem?.uuid,
              short_input: `${submissionStatus} ${isAnonymous ? "anonymous" : ""
                }`,
              long_input: freedomWallText,
            },
          ],
        },
      },
      onCompleted: (data) => {
        if (cookRecipes) {
          courseUuid
            ? cookRecipes.cookAsCompletedInsideCourse()
            : cookRecipes.cookAsCompletedOutsideCourse();
        }
        setFinishedSubmission(true);
        setToastOpen(true);
      },
    });
  const getToastStrings = () => {
    if (submissionStatus === "draft") {
      return {
        title: "Draft Saved",
        message:
          "Your response will still be here when you revisit this resource",
      };
    }

    if (!error) {
      return {
        title: "Freedom Wall Response Submission Successful!",
        message: "",
      };
    }

    return {
      title: "Freedom Wall Response Submission Failed",
      message: "",
    };
  };

  const navigate = useNavigate();

  const onReturn = () => {
    navigate(
      courseUuid
        ? `/courseviewer?course_uuid=${courseUuid}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}${searchParams.get("path")
          ? `&path=${searchParams.get("path")}&base_path=${searchParams.get("base_path")}&from_directory=${searchParams.get("from_directory")}`
          : ""
        }`
        : searchValue
          ? `/screen/search?search_value=${searchValue}`
          : searchParams.get("path")
            ? `/directoryviewer?from_screen=${parentScreenName}&path=${searchParams.get("path")}&base_path=${searchParams.get("base_path")}&from_directory=${searchParams.get("from_directory")}`
            : `/screen/${parentScreenName}`
    );
  };

  useEffect(() => {
    if (submissionStatus !== "") submitFreedomWallResponse();
  }, [submissionStatus]);

  return (
    <div className="max-w-6xl px-10 mx-auto my-4">
      {loading && (
        <div>
          <LoadingCentered />
        </div>
      )}
      {toastOpen ? (
        <Toast
          title={getToastStrings().title}
          message={getToastStrings().message}
          closeDelay={3000}
          toastStatus="success"
          onClose={() => setToastOpen(false)}
        ></Toast>
      ) : (
        <></>
      )}
      <div className="text-lg font-bold">
        <span className="text-error-500">
          {freedomWallItem?.required ? "* " : ""}
        </span>
        {freedomWallItem?.text}
      </div>
      <div className={`py-4 ${freedomWallItem?.mediaUrl ? "" : "hidden"}`}>
        <Image
          src={freedomWallItem?.mediaUrl || ""}
          alt={freedomWallItem?.mediaUrl || ""}
          className={`w-full min-h-40 max-h-[35rem] object-contain bg-opacity-0`}
        />
      </div>
      <InputTextArea
        disabled={finishedSubmission}
        onChange={(e) => setFreedomWallText(e.target.value)}
        value={freedomWallText}
        rows={4}
        name={freedomWallItem?.uuid || ""}
      />

      {/* Wala pa po atang ganitong functionality */}
      <label className="flex items-center text-gray-500 ">
        <input
          type="checkbox"
          onChange={() => {
            setIsAnonymous((prev) => !prev);
          }}
          className="mr-2 rounded focus:border-primary-300 focus:ring-primary-300 text-primary-600"
          disabled={finishedSubmission}
        />
        Submit answer anonymously
      </label>

      <div
        className={`flex w-fit mx-auto mb-4 ${finishedSubmission ? "hidden" : ""
          }`}
      >
        <Button
          className={"mr-2"}
          onClick={() => {
            setSubmissionStatus("draft");
            // setIsSubmitted(true);
          }}
          disabled={
            freedomWallItem?.required && freedomWallText === "" ? true : false
          }
        >
          Save As Draft
        </Button>

        <Button
          type="submit"
          color="secondary"
          disabled={
            freedomWallItem?.required && freedomWallText === "" ? true : false
          }
          onClick={() => {
            setSubmissionStatus("final");
          }}
        >
          Submit
        </Button>
        {/* )} */}
      </div>

      {/* <div className="h-screen mb-2 bg-blue-200">Responses Go here</div> */}

      {data && submissionStatus !== "draft" ? (
        <FreedomWallFeedback
          response={
            (data?.assessmentSubmitUpdateV2?.submissionResult
              ?.responses?.[0] as ExamResponseV2) ||
            (data?.assessmentSubmitUpdateV2?.courseBlockSubmissionResult
              ?.responses?.[0] as ExamResponseV2)
          }
        />
      ) : (
        <></>
      )}

      <Button
        className={`sticky min-w-fit max-w-1/5 text-sm bottom-4 left-0 right-0 mx-auto mb-4  ${finishedSubmission ? "" : "hidden"
          } `}
        color="primary"
        onClick={onReturn}
      >
        {courseUuid ? "Return to Course" : "Return to Screen"}
      </Button>
    </div>
  );
}

const FreedomWallFeedback = ({ response }: { response: ExamResponseV2 }) => {
  const [freedomWallResponseLength, setFreedomWallResponseLength] = useState(5);
  const [totalLength, setTotalLength] = useState<number>(0);

  useEffect(() => {
    setTotalLength(
      response.freedomWallResponses?.filter((r) => {
        return !r?.shortInput?.includes("draft");
      }).length || 0
    );
  }, [response]);

  const limitedResponse = response?.freedomWallResponses?.slice(
    0,
    freedomWallResponseLength
  );

  const filteredResponses = limitedResponse?.filter((response) => {
    return !response?.shortInput?.includes("draft");
  });

  return (
    <div>
      <div className="px-4 py-2 my-4 text-info-500 bg-info-50">
        <div className="font-bold">Your answer has been submitted</div>
        <div>
          <div className="text-left">{response?.feedbackMessage}</div>
          <Image
            src={response?.feedbackImageUrl || ""}
            alt="feedback image"
            className={`w-full object-contain min-h-24 max-h-[35rem] bg-opacity-0 ${response?.feedbackImageUrl ? "" : "hidden"
              }`}
          />
        </div>
      </div>
      {/* Freedom Wall*/}
      <div className="w-full">
        <h2 className="mb-4 text-2xl font-bold">Responses</h2>

        {filteredResponses?.map((freedomWallResponse) => {
          const profile = freedomWallResponse?.userDetails;
          const submissionStatus = freedomWallResponse?.shortInput || "";

          if (submissionStatus.includes("draft")) return <></>;

          return (
            <div
              key={freedomWallResponse?.uuid}
              className="w-full p-4 mb-2 bg-gray-200 rounded-lg "
            >
              <div className="flex items-center mb-4">
                <Image
                  className="w-8 h-8 mr-2 rounded-full"
                  src={profile?.profileIconUrl || ""}
                ></Image>
                <p className="font-semibold">
                  {/* Minsan po walang null na yung submitted by palang. Pag may laman naman minsan null din yung name. */}
                  {profile &&
                    profile.firstName &&
                    profile.lastName &&
                    !submissionStatus.includes("anonymous")
                    ? `${profile.firstName} ${profile.lastName}`
                    : "Anonymous user"}
                </p>
              </div>
              <p>{freedomWallResponse?.longInput} </p>
            </div>
          );
        })}
        <div
          className={`text-sm flex pb-2 ${freedomWallResponseLength === totalLength ? "hidden" : ""
            }`}
        >
          <Button
            className="mr-2"
            onClick={() => {
              setFreedomWallResponseLength((prev) => prev + 5);
            }}
          >
            See More
          </Button>
          <Button
            onClick={() => {
              setFreedomWallResponseLength(totalLength || 20);
            }}
          >
            Show All {totalLength} Responses
          </Button>
        </div>
      </div>
    </div>
  );
};
