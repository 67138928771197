import React, { useRef, ChangeEvent, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import useSubtitles from "react-subtitles";
import { Button } from "../Button";
import { TrackProps } from "react-player/file";
import { useCreateUrlFromBlob } from "../../../helpers/CreateUrlFromBlob/CreateUrlFromBlob";

type HlsInternalPlayer = {
  currentLevel: number;
  levels: Array<{ bitrate: number; attrs: { RESOLUTION: string } }>;
};

export interface VideoProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  file?: VideoObject;
  onPlay?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
  includeTranscriptButton?: boolean;
}

export interface VideoObject {
  uri?: string;
  videoDescription?: string;
  tracks?: Array<{ uri: string }>;
  dashUri?: string;
  hlsUri?: string;
  subtitleClass?: TextSize;
}

type TextSize =
  | "text-xs"
  | "text-sm"
  | "text-base"
  | "text-lg"
  | "text-xl"
  | "text-2xl"
  | "text-3xl";

const tailwindFontSizes: Record<TextSize, string> = {
  "text-xs": "12px",
  "text-sm": "14px",
  "text-base": "16px",
  "text-lg": "18px",
  "text-xl": "20px",
  "text-2xl": "24px",
  "text-3xl": "30px",
};

export function Video({
  file,
  onPlay,
  onPause,
  onEnd,
  includeTranscriptButton = true,
  ...props
}: VideoProps) {
  const videoBlobUrl = useCreateUrlFromBlob(file?.uri);
  const [subtitle] = useSubtitles({ subtitles: file?.tracks?.[0]?.uri || "" });
  const [openTranscript, setOpenTranscript] = useState<boolean>(false);
  const player = useRef<ReactPlayer>(null);

  const allTracks: TrackProps[] =
    file?.tracks?.map((track, i) => ({
      kind: "subtitles",
      src: track.uri || "",
      srcLang: "en",
      label: `Subtitle ${i + 1}`,
    })) || [];

  useEffect(() => {
    if (file?.subtitleClass) {
      const fontSize = tailwindFontSizes[file.subtitleClass] || "16px";
      const style = document.createElement("style");
      style.innerHTML = `::cue { font-size: ${fontSize}; color: white; }`;
      document.head.appendChild(style);

      return () => {
        document.head.removeChild(style);
      };
    }
  }, [file?.subtitleClass]);

  const onChangeBitrate = (event: ChangeEvent<HTMLSelectElement>) => {
    const internalPlayer = player.current?.getInternalPlayer(
      "hls"
    ) as HlsInternalPlayer | null;
    if (internalPlayer) {
      internalPlayer.currentLevel = parseInt(event.target.value, 10);
    }
  };

  return (
    <div className={`${props.className} flex flex-col`}>
      <div className="flex w-full h-full">
        <ReactPlayer
          ref={player}
          url={videoBlobUrl || file?.hlsUri || file?.dashUri || file?.uri}
          height="100%"
          width="100%"
          style={{ backgroundColor: "black" }}
          controls
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnd}
          config={{
            file: { tracks: allTracks, attributes: { crossOrigin: "true" } },
          }}
        />
        {openTranscript && (
          <div className="w-1/3 h-full ml-4 overflow-scroll" id="transcript">
            {subtitle.map((s, i) => (
              <h1 key={i} className="mb-4">
                {s.text.replace(/&nbsp;/gi, " ").replace(/&amp;/gi, "&")}
              </h1>
            ))}
          </div>
        )}
      </div>
      <div className="flex w-full my-4">
        {includeTranscriptButton && (
          <Button
            onClick={() => {
              if (file?.tracks?.length) setOpenTranscript(!openTranscript);
              else alert("No Transcript for this video");
            }}
          >
            {openTranscript ? "Close Transcript" : "Show Transcript"}
          </Button>
        )}
        {player.current?.getInternalPlayer("hls")?.levels.length > 1 && (
          <div className="flex items-center ml-auto space-x-2">
            <p className="text-lg">Quality:</p>
            <select onChange={onChangeBitrate} className="py-2 rounded-lg">
              {player.current
                ?.getInternalPlayer("hls")
                ?.levels.map((level: any, id: any) => (
                  <option key={id} value={id}>
                    {level.attrs.RESOLUTION.split("x")[1]}p
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}
