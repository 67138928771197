import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "../../../atoms/components";
import {
  Profile,
  ProfileIconFromText,
} from "../../../organisms/components/Navbar";
import { useLocation } from "react-router-dom";
import { isGuestContext } from "../../../context/components/IsGuest";

export interface ProfileMenuProps {
  profile: Profile;
  setShowProfile?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ProfileMenu({ setShowProfile, profile }: ProfileMenuProps) {
  const ref = useRef<any>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e: Event) => {
    if (ref.current != null) {
      if (!ref.current.contains(e.target)) {
        if (setShowProfile) setShowProfile(false);
      }
    }
  };

  const location = useLocation();

  const { isGuest } = useContext(isGuestContext);

  return (
    <div
      className="fixed top-0 left-0 z-[100] w-screen h-full p-4 bg-white shadow-lg md:absolute md:w-fit md:h-fit md:right-2 md:top-16 md:left-auto md:rounded-lg"
      ref={ref}
    >
      <button onClick={() => (setShowProfile ? setShowProfile(false) : "")}>
        <Icon name="chevron_left"></Icon>
      </button>

      {/* User profile info */}
      {/* w-12 h-12 */}
      <div className="flex items-center mx-2">
        <ProfileIconFromText
          text={
            (profile.userInfo.firstName !==
              "DEPRECATED - use traits.given_name" &&
            profile.userInfo.firstName !== null
              ? profile.userInfo.firstName?.[0]
              : "Guest") +
            " " +
            (profile.userInfo.lastName !==
              "DEPRECATED - use traits.family_name" &&
            profile.userInfo.firstName !== null
              ? profile.userInfo.lastName?.[0]
              : "Account")
          }
          size={12}
        ></ProfileIconFromText>

        {/* Name Here */}
        <div className="ml-2 text-sm">
          {/* Missing Middle Initial */}
          <h3 className="font-bold leading-none text-gray-700">{`${
            profile.userInfo.firstName !==
              "DEPRECATED - use traits.given_name" &&
            profile.userInfo.firstName !== null
              ? profile.userInfo.firstName
              : "Guest"
          } ${
            profile.userInfo.lastName !==
              "DEPRECATED - use traits.family_name" &&
            profile.userInfo.firstName !== null
              ? profile.userInfo.lastName
              : "Account"
          }`}</h3>
          <h4 className="leading-tight text-gray-500 ">
            {isGuest ? "" : profile.userInfo.email}
          </h4>
        </div>
      </div>

      <hr className="m-2 bg-gray-200" />

      {/* Screen Names */}
      <div>
        {!process.env.REACT_APP_COMELEC &&
          !/comelec/g.test(window.location.href) && (
            <Link
              to={`../../profile/certification`}
              className="flex items-center flex-none px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200"
            >
              <p>Course and Certification</p>
            </Link>
          )}

        {(/fsi/g.test(window.location.href) ||
          /bokchoy/g.test(window.location.href) ||
          /training/g.test(window.location.href) ||
          /localhost:3000/g.test(window.location.href)) && (
          <Link
            to={`../../profile/training-info`}
            className="flex items-center flex-none px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200"
          >
            <p>Domains and Training Indicator</p>
          </Link>
        )}

        <Link
          to={`/profile/activitylog`}
          className="flex items-center flex-none px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200"
        >
          <p>Activity Log</p>
        </Link>

        <Link
          to={`/profile/account-and-user-details`}
          className="flex items-center flex-none px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200"
        >
          <p>Account and User Details</p>
        </Link>

        {/buribooks/g.test(window.location.href) ? (
          <div>
            <Link
              to={`/profile/purchases`}
              className="flex items-center flex-none px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200"
            >
              <p>Purchases</p>
            </Link>

            <Link
              to={`/profile/subscriptions`}
              className="flex items-center flex-none px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200"
            >
              <p>Subscriptions</p>
            </Link>
          </div>
        ) : (
          ""
        )}

        {/* <Link
          to={`/`}
          className="flex items-center flex-none px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200"
        >
          <p>App Settings</p>
        </Link> */}
      </div>

      <Link
        className="block px-4 py-2 ml-1 text-gray-500 uppercase transition-colors rounded w-fit hover:text-white hover:bg-primary-600"
        to="/logout"
      >
        Logout
      </Link>
    </div>
  );
}
