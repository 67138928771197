import { Link, useSearchParams } from "react-router-dom";
import {
  CastBlockPropertiesDirectory,
} from "../../../generated/graphql";
import { parseColorToStyle } from "../../../helpers/ColorHelper";
import {
  ScreenModal,
  TimerChancesPromptModal,
} from "../../../molecules/components/CastBlocks";
import { DirectoryCard } from "../../../molecules/components/DirectoryCard";
import { Icon } from "../../../atoms/components";
import { CamelCasedType } from "../../../helpers/components";
import { ResourceTypeSwitch } from "../CatalogBlock";

export interface DirectoryBlockProps {
  properties: CamelCasedType<CastBlockPropertiesDirectory>;
  screenModal: ScreenModal;
  timerChancesPromptModal: TimerChancesPromptModal;
}

export function DirectoryBlock({
  properties,
  screenModal,
  timerChancesPromptModal,
}: DirectoryBlockProps) {

  const directories = properties?.directory
  const resources = properties.resources;
  const basePath = properties.basePath
  const limit = 10 // TODO: limit must be added to CastBlockPropertiesDirectory similar to CastBlockPropertiesCatalog

  const [searchParams] = useSearchParams();
  const pathArray = window.location.pathname.split("/");
  const parentScreenName = pathArray.pop();

  // TODO: appearance must be added to CastBlockPropertiesDirectory similar to CastBlockPropertiesCatalog
  // const bgStyle = parseColorToStyle(appearance?.base);

  // Old card header background color to resource icon color
  const resourceIconStyle = parseColorToStyle({
    // foregroundColor: appearance?.cardHeader?.backgroundColor,
    foregroundColor: {
      alpha: 1,
      blue: 75,
      green: 0,
      red: 75,
    }
  });

  let filteredResources = resources?.filter((resource) => {
    if (resource?.content?.__typename === "ResourceContentCourse") {
      let allowedActions =
        resource?.content?.courseDetails?.course?.allowedActions;
      return (
        allowedActions?.includes("take") || allowedActions?.includes("enroll")
      );
    } else {
      return true;
    }
  });

  const adjustedLimitForResources = limit ? properties.directory ?
    properties.directory.length >= limit ? 0 : limit - properties.directory.length
    : limit
    : undefined

  const allowedDirectories = limit
    ? directories?.slice(0, limit)
    : directories;

  const allowedResources = limit
    ? filteredResources?.slice(0, adjustedLimitForResources)
    : filteredResources;


  if (allowedDirectories?.length === 0 && allowedResources?.length === 0) {
    return <></>;
  }

  return (
    <div
      className="w-full px-10 py-6 md:px-20 animation-fade-in"
    >

      <div className={"flex justify-between text-2xl mb-4"}>
        <p className="overflow-hidden font-black text-gray-900 whitespace-nowrap overflow-ellipsis">
          Directory Block
        </p>

        {/* Link to Directory Screen */}
        <Link
          to={`/directoryviewer?path=${basePath}&from_screen=${parentScreenName}&from_directory=seemore&base_path=${basePath}`}
          className={
            (limit ? "" : "hidden") + " text-secondary-400 text-lg px-2"
          }
        >
          <span className="hidden md:inline">See more</span>
          <Icon name="chevron_right" className="text-xl" />
        </Link>
      </div>

      {/* DISPLAYED DIRECTORIES */}
      <ul className="flex flex-col flex-wrap items-center md:flex-row">

        {allowedDirectories?.map((directory, i: number) => {
          return (

            <Link
              key={directory?.path}
              to={`/directoryviewer?path=${directory?.path}&from_screen=${parentScreenName}&from_directory=main&base_path=${basePath}`}
            >
              <DirectoryCard
                path={directory?.path}
                resourceIconStyle={resourceIconStyle}
              />
            </Link>

          );
        })}

        {/* DISPLAYED RESOURCES */}
        {allowedResources?.map((resource, i: number) => {
          return (
            <ResourceTypeSwitch
              key={resource?.uuid}
              resource={resource || {}}
              resourceIconStyle={resourceIconStyle}
              screenModal={screenModal}
              timerChancesPromptModal={timerChancesPromptModal}
              parentScreenName={parentScreenName}
            />
          );
        })}
      </ul>
    </div>
  );
}
