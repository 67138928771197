import { Icon } from "../../../atoms/components";

export interface DirectoryCardProps {
  path: string | null | undefined;
  key?: string;
  resourceIconStyle?: any;
}

export function DirectoryCard({
  path,
  key,
  resourceIconStyle,
}: DirectoryCardProps) {
  if (!path) return null;

  function getLastPathItem(path: string): string {
    const pathArray = path.split('/').filter(Boolean);
    return pathArray[pathArray.length - 1];
  }

  return (
    <div
      key={key}
      className="relative flex flex-col items-center justify-center w-48 h-48 px-4 pt-1 mb-2 bg-gray-100 rounded-2xl md:mr-2"
    >
      <div className="relative flex flex-col items-center group/image-group">
        <Icon name="folder" className="text-9xl text-sky-300" />
      </div>

      <p className="flex items-center justify-center w-full h-full font-semibold text-center text-gray-700 break-words">
        <span className="line-clamp-2">{path ? getLastPathItem(path) : ''}</span>
      </p>
    </div>
  );
}
