import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  CarouselCastBlock,
  CategoryBlock,
  MarkdownCastBlock,
  SearchButtonBlock,
} from "../../../molecules/components";
import { CatalogBlock } from "../../../organisms/components/CatalogBlock";
import { EnrollmentModals } from "../../../organisms/components";
import { CastBlocksQuery, CastBlock } from "../../../generated/graphql";
import { ResourceCollection } from "../CategoryBlock";
import { CastBlockPropertiesButtonSearch } from "../SearchButtonBlock";
import { DirectoryBlock } from "../../../organisms/components/DirectoryBlock";

export interface CourseResource {
  image: string;
  name: string;
  tags?: string[];
  description: string;
  courseOutline?: CourseOutline[];
  uuid?: string;
  type?: string;
}

export interface CourseOutline {
  courseBlockTitle: string;
  courseBlockIcon: string;
}

export interface batchModal {
  status: string;
  batchModalName: string;
  start: Date;
  end: Date;
}

export interface ScreenModal {
  objectFunction: {
    setCourseCardModal: React.Dispatch<React.SetStateAction<boolean>>;
    setBatchModal: React.Dispatch<React.SetStateAction<boolean>>;
    setCourseResource: React.Dispatch<React.SetStateAction<CourseResource>>;
    setResourceUuid: React.Dispatch<React.SetStateAction<string>>;
  };
  courseResource?: CourseResource;
  batchModalSchedules?: batchModal[];
  state: boolean;
}
export interface TimerChancesPromptModal {
  objectFunction: {
    setResourceUuid: React.Dispatch<React.SetStateAction<string>>;
    setTimePromptModal: React.Dispatch<React.SetStateAction<boolean>>;
  };
  state: boolean;
}
export interface CastBlocksProps {
  data: CastBlocksQuery;
}

export function CastBlocks({ data }: CastBlocksProps) {
  const [maxBlocks, setMaxBlocks] = useState<number>(1);

  // Setting length of data to be rendered to avoid rendering all at once.
  let currentBlocks = data?.castBlockScreen?.castBlocks?.slice(0, maxBlocks);

  const [ref, inView, entry] = useInView({
    // Percent of how much of the element must be intersected to trigger intersection
    threshold: 0,
  });

  // Decided to implement use Effect rather than on change to be more flexible on different screen sizes
  // Had to put the second argument to avoid over re-rendering
  useEffect(() => {
    if (
      inView &&
      maxBlocks < (data?.castBlockScreen?.castBlocks?.length || 0)
    ) {
      setMaxBlocks(maxBlocks + 1);
    }
  }, [inView, maxBlocks]);

  const [courseCardModal, setCourseCardModal] = useState<boolean>(false);
  const [batchModal, setBatchModal] = useState<boolean>(false);
  const [courseResource, setCourseResource] = useState<CourseResource>();
  const [resourceUuid, setResourceUuid] = useState<string>("");
  const [timePromptModal, setTimePromptModal] = useState<boolean>(false);
  const screenModal: ScreenModal = {
    objectFunction: {
      setCourseCardModal: setCourseCardModal,
      setBatchModal: setBatchModal,
      setCourseResource: setCourseResource as React.Dispatch<
        React.SetStateAction<CourseResource>
      >,
      setResourceUuid: setResourceUuid,
    },
    state: courseCardModal,
  };
  const timerChancesModal: TimerChancesPromptModal = {
    objectFunction: {
      setTimePromptModal: setTimePromptModal,
      setResourceUuid: setResourceUuid,
    },
    state: timePromptModal,
  };

  return (
    <>
      {/* Modal Course Card Goes Here */}
      {batchModal || courseCardModal || timePromptModal ? (
        <EnrollmentModals
          courseCardModal={courseCardModal}
          setCourseCardModal={setCourseCardModal}
          batchModal={batchModal}
          setBatchModal={setBatchModal}
          courseResource={courseResource}
          screenModal={screenModal}
          resourceUuid={resourceUuid}
          setTimePromptModal={setTimePromptModal}
          timePromptModal={timePromptModal}
        ></EnrollmentModals>
      ) : (
        <></>
      )}

      {/* Map currrent blocks */}
      {currentBlocks?.map((block, index: number) => (
        <div key={index}>
          <div
            className={courseCardModal ? "pointer-events-none opacity-75" : ""}
          >
            <CastBlockItem
              block={block || {}}
              screenModal={screenModal}
              timerChancesModal={timerChancesModal}
            />
          </div>
        </div>
      ))}
      {/* To be replaced when approved. */}
      <div ref={ref} className=" h-20 w-full"></div>
    </>
  );
}

// Separated switch for code clarity
function CastBlockItem({
  block,
  screenModal,
  timerChancesModal,
}: {
  block: CastBlock;
  screenModal: ScreenModal;
  timerChancesModal: TimerChancesPromptModal;
}) {
  let blockProperties = block?.attributes?.properties;

  switch (block?.attributes?.type) {
    case "carousel": {
      if (blockProperties?.__typename === "CastBlockPropertiesCarousel")
        return (
          <CarouselCastBlock
            imageURLs={blockProperties?.items?.map(
              (element: any) => element?.src || element?.url || element?.image
            )}
          />
        );

      return <></>;
    }

    case "catalog": {
      return (
        <CatalogBlock
          castBlockId={block.id || ""}
          screenModal={screenModal}
          timerChancesPromptModal={timerChancesModal}
        />
      );
    }

    case "directory": {
      if (blockProperties?.__typename === "CastBlockPropertiesDirectory")
        return (
          <div>
            <DirectoryBlock
              properties={blockProperties}
              screenModal={screenModal}
              timerChancesPromptModal={timerChancesModal}
            />
          </div>
        );

      return <></>;
    }

    case "markdown": {
      if (blockProperties?.__typename === "CastBlockPropertiesMarkdown")
        return <MarkdownCastBlock text={blockProperties.content} />;

      return <></>;
    }

    case "buttonSearch": {
      return <SearchButtonBlock block={block} />;
    }

    case "category": {
      if (
        block.attributes.properties?.__typename ===
        "CastBlockPropertiesCategory"
      )
        return (
          <CategoryBlock
            collections={
              (block.attributes.properties
                .collections as ResourceCollection[]) || []
            }
            castBlockUuid={block.id || ""}
          />
        );

      return <></>;
    }

    default: {
      return <></>;
    }
  }
}
